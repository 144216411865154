import React from "react";
import Layout from "../components/layout"

const Envios = () => {

    return (
        <Layout>
            <main className="row">
                <div className="col-12  m-auto py-5">
                    <h5 className="text-center">POLITICA DE ENVIOS</h5>
                    <br/>
                    <div style={{textAlign:'justify'}}>
                        <p>A los <strong>Usuarios</strong>, les informamos que la siguiente Política de Envíos les son aplicables por el simple uso o
                        acceso a cualquiera de las páginas, aplicaciones web y móviles, softwares y, aplicaciones en general, que
                        integran el Portal de la Empresa a través de la siguiente dirección <a href="http://www.construshop.mx/" target="_blank" rel="noreferrer">www.construshop.mx</a> y por la adquisición
                        de cualquier producto o servicio adquirido por el <strong>Usuario</strong> dentro del <strong>Portal</strong>, por lo que entenderemos que
                        los acepta y acuerda en obligarse a su cumplimiento. <strong>En el caso de que no esté de acuerdo con la
                        presente Política de Envíos deberá abstenerse de acceder o utilizar el Portal y adquirir cualquier
                        producto o servicio dentro del Portal</strong>.</p>
                        
                        <p>La <strong>Empresa</strong> se reserva el derecho de modificar discrecionalmente el contenido del <strong>Portal</strong> o de la presente
                        Política de Envíos en cualquier momento, sin necesidad de previo aviso.</p>

                        <p><strong>El Usuario</strong>, es entendido como aquella persona que realiza el uso o accede al <strong>Portal</strong>, mediante equipo de
                        cómputo y/o cualquier equipo de comunicación o dispositivo (en adelante el <strong>“Usuario”</strong>).</p>

                        <p>La satisfacción de nuestros <strong>Usuarios</strong> sobre nuestros productos y servicios es lo más importante para 
                        nosotros, por lo que ponemos a tu disposición la siguiente Política de Envíos.</p>
                        <br/>
                        <h5><u>COSTO POR ENVIO</u></h5>
                        <br/>
                        <p>Los precios publicados por cada producto o servicio dentro del <strong>Portal</strong> NO incluyen el costo por el envío
                        del mismo, salvo que expresamente lo indique o aplique alguna promoción particular y vigente. Los precios
                        y promociones pueden cambiar en cualquier momento sin previo aviso.</p>
                        <br/>
                        <h5><u>INFORMACION DE ENVIO</u></h5>
                        <br/>
                        <p>El <strong>Usuario</strong> debe de proporcionar la siguiente información para procesar su envío: </p>
                        <ul>
                            <li>Nombre</li>
                            <li>Dirección</li>
                            <li>C&oacute;digo postal</li>
                            <li>Correo electr&oacute;nico</li>
                            <li>N&uacute;mero de tel&eacute;fono</li>
                        </ul>
                        <p>El <strong>Usuario</strong> se compromete a brindar al <strong>Portal</strong> una dirección válida, real, completa y localizable para el
                        envío y su debida entrega.</p>
                        <p>En caso de que el <strong>Usuario</strong> proporcione una dirección no válida, no localizable, inexistente, incompleta o
                        errónea para el envío y entrega de los productos o servicios adquiridos en el <strong>Portal</strong> y el paquete sea
                        devuelto por el servicio de paquetería o de envíos, el <strong>Usuario</strong> será el responsable de hacer el pago del
                        nuevo envío.</p>
                        <p><strong><u>No podrá procesarse ninguna compra con una dirección de entrega incompleta.</u></strong></p>

                        <br/>
                        <h5><u>AREA DE REPARTO</u></h5>
                        <br/>
                        <p>Ofrecemos envíos NACIONALES. Entregamos en todas las localidades donde existe cobertura a través de
                        las empresas de paquetería con las que el <strong>Portal</strong>, a su discreción, decide trabajar. El <strong>Usuario</strong> reconoce y
                        acepta que el <strong>Portal</strong>, depende totalmente de las condiciones de envío y entrada de cada una de las
                        compañías de paquetería y de envíos, así como de las condiciones climáticas, zonas de riesgo, o cualquier
                        otra que pueda afectar las condiciones y tiempos de entrega.</p>
                        <br/>
                        <h5><u>TIPOS DE ENVIO</u></h5>
                        <br/>
                        <p>El <strong>Portal</strong> realiza envíos A DOMICILIO, mismos que tienen los siguientes costos y características
                        aproximados. Dichas características y costos pueden variar según el destino del envío, el peso y medidas,
                        el contenido, así como cualquier otro y pueden cambiar sin previo aviso.</p>
                        <br/>
                        <ul>
                            <li>El envío se realiza por parte del propio proveedor de materiales.</li>
                            <li>Los envíos son entregados en un plazo de 5 a 7 días hábiles. </li>
                        </ul>
                        <br/>
                        <h5><u>TIEMPOS DE ENTREGA</u></h5>
                        <br/>
                        <p>Los pedidos son procesados en un plazo de 1 a 2 días hábiles una vez que el pago de tu pedido es
                        aprobado. El tiempo estimado,número de guía (en caso de que aplique), será confirmado al <strong>Usuario</strong> por
                        correo electrónico.</p>
                        <br/>
                        <h5><u>INCIDENCIAS EN EL ENVIO</u></h5>
                        <br/>
                        <p>Si por algún motivo el producto recibido no es el producto adquirido o presente alguna anomalía, es
                        indispensable que el <strong>Usuario</strong> se ponga en contacto con la Empresa en un plazo máximo de 24 horas una
                        vez recibido el paquete al correo electrónico <a href="mailto:inaki.lopez@construshop.mx">inaki.lopez@construshop.mx</a> o al teléfono <a href="tel:5549503068">5549503068</a>.</p>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default Envios

export const Head = () => <title>Pol&iacute;tica de env&iacute;os</title>